/* rounded indicators test
div.carousel-indicators {
    height:5px;
    display:flex;
    gap:10px;
    align-items: center;
    justify-content: center;
    
    button {
        background: #FFF;
        width:5px;
        height:5px;
        border-radius:50%;
        box-shadow: 0 0 4px 4px rgba(0,0,0,0.25);
    }

    button.active {
        background: #FFF;
        width:10px;
        height:10px;
        border-radius:50%;
    }
}*/

.carousel-item {
    height:150px !important;
}
.breadcrumb {
    font-size: 12px;
}

#breadcrumb-mobile-button {
    display:none;
}

@media screen and (max-width: 640px) {
    ol.breadcrumb {
        padding:0 !important;
        li {
            display:none;
    
            &:first-child, &:last-child {
                display:block
            }
        }
    }
    
    #breadcrumb-mobile-button {
        display:block;
        position:absolute;
        right:1em;
        margin-top:-1.4em;
    }
}
//init proactive search fieldsets
#jobForm fieldset:nth-child(n+3) > div,
#jobForm .briefing,
#jobForm #labelCondicionesLegales span
 {
    display:none;
}

#jobFormLanding {
    .p-5 {
        padding:10px;
    }
}

#jobFormInfo {

    min-width:300px;
    
    h1 {
        color:#FFF;
        background-image: url('../img/jobform.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        font-size:2em;
        padding:0.5em;

        span {
            margin-top:160px;
            display:block;
            font-size:1.5rem;
        }
    }
}

@media screen and (min-width: 641px) {

    #jobFormInfo {
        h1 {
            font-size:2.5em;
            padding:1em;

            span {
                font-size:1.8rem;
            }
        }
    }
}
.navbar-collapse {
    flex-grow: 1;
}

a.navbar-brand {
    display:none;
}

li.private {
    background-color:#102A43;
    color:#FFF;
}

li.nav-item {
    padding: 5px 20px;
}

li.breadcrumb-item {
    color:#102A43;
}
li.breadcrumb-item a {
    color:#627D98;
}

.nav-item {
    i {
        font-size:18px;
        font-weight:bold;
    }
    .badge {
        background-color: #486581;
        color: #FFFFFF;
        font-size: 10px;
        border-radius: 5px;
        padding: 1px 5px;
        margin-left:-1em;
    }
}

#nav-favorites {
    .nav-link {
        padding: 4px;
    }
}

@media screen and (max-width: 640px) {
    body {
        margin-top: 3.5em;
    }

    nav.navbar {
        position: fixed;
        top:0;
        border-bottom: 1px solid #D9E2EC;
        z-index:99;
        left:0%;
        padding:0 10px;

        button {
            border:0;
        }
    }

    #logo {
        display:none;
    }

    a.navbar-brand {
        display:block;
    }

    .navbar.navbar-light {
            background-color:#FFFFFF;

            li {
                border-bottom: 1px solid #D9E2EC;
            }

            a.nav-link, a.nav-link:hover {
                color: #102A43;
            }

            li.private {
                background-color: #F0F4F8;
            }

            #nav-favorites {
                li.private {
                    background-color: #FFF;
                    border:0;
                }
            }
        }
}

.navbar.navbar-light {
    background-color: #94B247;

    a.nav-link {
        color:#FFF;
    }

    a.nav-link:hover {
        color:#FFF;
        text-decoration:underline;
    }
    
}

#banner-header {
    max-width:768px;
    max-height:90px;
}
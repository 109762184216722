div.item-list {
    color: #627D98;
}

.list {
    dt {
        display: list-item;
        list-style-type: disc;
    }
}

#facets {
    display:none;
}

.favButton {
    position: absolute;
    margin-top: -145px;
    margin-left: 260px;
    color: #FFF;
    z-index: 10;
    cursor: pointer;

    a {
        color:#FFFFFF;
    }

    .bi {
        font-size:1.5rem;
    }
}

h2 a {
    color:#6F8B27;
    text-decoration:underline;
}

#favMenu {

    flex: 0 0 30%;
    text-align:right;

    a {

        color: #627D98;
        margin: 0 1em;
        padding-bottom:5px;
        margin-bottom:-8px;

        &.active {
            color: #102A43;
            border-bottom: 2px solid #94B247;
        }
        
    }
}

#list-header {
    h1 {
        flex: 0 0 70%;
    }
}

.col-md-3 {
    div.item-list {
        margin-right:0 !important;

        h1, h2 {
            margin-top:10px;
        }

        .residents-info {
            border-bottom: 1px solid #D9E2EC;
            border-top: 1px solid #D9E2EC;
            padding-top:10px;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 10px;

            p {
                text-align:center;
            }
        }
    }
}

.related-links {
    ul {
        display:grid;
        grid-template-columns: repeat( auto-fit, minmax(50%, 1fr) );

        li {
            list-style:none;
            padding: 0.1rem 0;
            font-size:0.8rem;
            display: flex;

            a {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width:80%;
            }
        }
    }
}

@media screen and (min-width: 641px) {
    #facets-button {
        display:none;
    }

    #facets {
        display:block;
    }

    #list-banners-mobile {
        display:none;
    }

    .related-links ul {
        grid-template-columns: repeat( auto-fit, minmax(25%, 1fr) );
    }
}

.residents-info {
    img {
        display:inline-block;
        padding-right:5px;
        height:22px;
        margin-top:-5px;
    }

    p {
        font-size:0.9em;
    }
}

#whatsapp {
    display:none;
}

@media screen and (max-width: 640px) {
    div.item-list {
        margin-right:0 !important;

        h1, h2 {
            margin-top:10px;
        }

        .residents-info {
            border-bottom: 1px solid #D9E2EC;
            border-top: 1px solid #D9E2EC;
            padding-top:10px;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 10px;

            p {
                text-align:center;
            }
        }
    }

    #list-header {
        
        display:flex;
        align-items: center;
        justify-content: center;


        h1 {
            font-size:14px;
            margin:0;
            padding:0;
            span {
                font-size:18px;
            }
        }

        #facets-button {
            border: 1px solid #D9E2EC;
            border-radius: 4px;
            flex: 0 0 30%;
            padding: 5px 3px;
            text-align: center;
        }
        
    }

    #favMenu {
        display:none;
    }

    #list-banners-desktop {
        display:none;
    }

    #whatsapp {
        display:block;
        position: fixed;
        bottom: 10px;
        right: 10px;
        background: #FFF;
        padding: 2px 10px 2px 50px;
        border-radius: 10px;
        width: 150px;
        box-shadow: 0 0 5px 5px rgba(0,0,0,0.1);
        line-height:18px;

        a {
            color:#1e9c4d;
            line-height:1rem;
        }

        img {
            position: absolute;
            margin-left:-45px;
        }
    }
}

#asesoramiento-telefonico {
    padding-left:110px;
    background:url('../img/asesora.png') no-repeat 10px 10px;
    min-height:130px;

    p {
        background:url('../img/phone.png') no-repeat;
        min-height:60px;
        padding-left:30px;
        margin-left:10px;
        
        span {
            font-size:1.8rem;
        }
    }
}

@media screen and (max-width: 640px) {
    #asesoramiento-telefonico {
        p span {display:block}
    }
}
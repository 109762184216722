legend {
    font-size:20px;
    font-weight:normal;
    color: #627D98;
    border-bottom: 1px solid #D9E2EC;
}

legend.active {
    color: #102A43;
    font-weight:bold;
}

label {
    font-weight:bold;
    font-size:12px;
    color: #486581;
}

label.form-check-label {
    font-weight:normal;
}

#reviewForm i {
    font-size:30px;
    color: #DCCB70;
    cursor:pointer;
}

select.form-select, textarea.form-control {
    color:#627D98;
}

button.btn-close {
    border: 0;
    background-color: transparent;
    font-size: 40px;
    margin: 0;
    padding: 0;
    color: #9FB3C8;
    line-height: 0;
}

form p.changeData {
    text-align:right;
}

#formulario-busqueda-proactiva fieldset button {
    width:100%;
}

#formulario-busqueda-proactiva p#avgPrice {
    font-size: 12px;
    color: #696969;
}

.benefits {

    padding:0;

    & > * {

        list-style:none;
        padding-left:65px;
        margin-bottom:1rem;
        min-height:50px;

    }

    .search {
        background: url("../img/benefit-search.svg") no-repeat 0 0;

    }

    .jobs {
        background: url("../img/benefit-jobs.svg") no-repeat 0 0;

    }

    .providers {
        background: url("../img/benefit-providers.svg") no-repeat 0 0;

    }

    .reports {
        background: url("../img/benefit-reports.svg") no-repeat 0 0;

    }

}

#register-options {
    display:flex;
    flex-wrap: wrap;
    gap:5%;

    & > * {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 45%;

        a {
            display:block;
            min-height:140px;
            font-size:24px;
            text-align:left;
            padding-left:120px;

            &.register-option-center {
                background: #95B14D url("../img/register-option-center.svg") no-repeat 20px  ;
            }

            &.register-option-sad {
                background: #95B14D url("../img/register-option-sad.svg") no-repeat 20px  ;
            }

            &.register-option-teleasistencia {
                background: #95B14D url("../img/register-option-teleasistencia.svg") no-repeat 20px  ;
            }

            &.register-option-provider {
                background: #95B14D url("../img/register-option-provider.svg") no-repeat 20px  ;
            }
        }
    }
}

@media screen and (max-width:640px) {
    #contact-form .col-md-9 {
        order:2;
        margin-left:0;
    } 
    #contact-form .col-md-3 {
        order:1;
        padding-left:0;

        & > div {
            margin-left:0;
        }
    }    
}

.formReal {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}
div#job-list, div#infoclasificados-list {
    a {
        text-decoration:none;

        &:hover div {
            background-color: #F0F4F8;
        }
    }

    h2 {
        margin:0;
        padding:0;
    }

    p.date {
        color: #9FB3C8;
        font-size:0.8rem;
        margin:0;
        padding:0;
    }

    ul.jobs-properties {
        li {
            list-style:none;
            color: #9FB3C8;
            font-size:0.8rem;

            &.job-property-day-shift {
                list-style-image:url("../img/joblist-properties-day-shift.png")
            }

            &.job-property-night-shift {
                list-style-image:url("../img/joblist-properties-night-shift.png")
            }

            &.job-property-fulltime {
                list-style-image:url("../img/joblist-properties-fulltime.png")
            }

            &.job-property-parttime {
                list-style-image:url("../img/joblist-properties-parttime.png")
            }

            &.job-property-experience {
                list-style-image:url("../img/joblist-properties-experience.png")
            }

            &.job-property-studies {
                list-style-image:url("../img/joblist-properties-studies.png")
            }

        }
    }
}
#left-column, #right-column {
    font-size:12px;

    h2, h3 {
        font-size:14px;
        color: #486581,
    }

    h2, h3, h4 {
        &.text-success {
            color: #DCCB70;
        }
    }

    h4 {
        font-size:12px;
        color: #486581,
    }
}

#card-rooms {
    font-size:10px;
    line-height:1.1;

    div.col {
        min-width:30%;
        padding:5px;
    }
}

#card-financing, #card-admissions {
    p {
        padding-left:15px;
        line-height:1.1;

        i {
            position:absolute;
            margin-left:-20px;
            font-weight:bold;
            font-size:18px;
        }
    }
}

#card-facilities-space {
    p {
        font-size: 11px;
        color: #486581;
    }

    .h1 {
        font-size: 20px;
        color: #102A43;
    }
}

#card-facilities-items {
    li {
        margin-top:20px;
        list-style:none;
        padding-left:2.5em;
        line-height:1.1;
    }

    img {
        position:absolute;
        margin-left:-2.5em;
    }

    del {
        color: #9FB3C8;
    }
}

#card-services {
    img {
        position:absolute;
        margin-left: 0;
    }

    p {
        padding-left: 25px;
        margin-bottom:0;
    }

    p.text-secondary {
        font-size:10px;
        line-height:1;
    }
}

#card-transparency {

    img {
        width:38px;
        margin:0;
    }

    i,img {
        position:absolute;
    }
    p {
        padding-top: 5px;
        padding-right:0;
        margin-bottom: -10px;
        padding-left:40px;
    }

    #card-transparency-info {
        i {
            font-size:18px;
            color: #DCCB70;
        }

        p {
            padding-left: 2em;
            line-height:1.1;
        }
    }
}

#card-transparency-info-detail {
    
    padding: 20px 10px 20px 30%;

    img {
        width:38px;
        margin:0;
    }

    i,img {
        position:absolute;
    }
    p {
        padding-top: 5px;
        padding-right:0;
        margin-bottom: -10px;
        padding-left:40px;
    }
}

#card-documentation {
    font-size: 14px;

    dt {
        margin-top:1em;
        padding-left: 20px;
    } 

    dd {
        padding-left: 20px;
        color: #627D98;
    } 
    
    dt i {
        position:absolute;
        margin-left: -20px;
    }
}

#card-review-stars {
    color: #627D98;

    i {
        font-size:18px;
        color: #DCCB70;
    }
    
    p {
        font-size: 10px;
    }

    #card-review-report {
        i {
            position:absolute;
            color: #627D98;
        }

        p {
            padding-left:2.5em;
            line-height:1;
        }
    } 
}

.card-comment-header {
    i {
        font-size:27px;
        position:absolute;
        color: #D9E2EC;
        margin:0;
        padding:0;
        width:auto;
    }

    p {
        padding:0 0 0 3em;
        margin:0;
    }
}

.card-comment-response {
    color: #627D98;
}

#card-contact-buttons {
    display:none;
}

.carousel-item.active, .carousel-item-start, .carousel-item.active > a {
    display:flex;
    height:inherit;
    align-items:center;
}

@media screen and (min-width: 576px) {
    .modal-dialog {
        max-width:700px !important;
    }
}

.modal-content .carousel-item {
    height:370px !important;
}

.modal-content {
    width:100% !important;
    height:auto !important;
}

.modal-content .carousel-inner {
    width:100% !important;
}

@media screen and (max-width: 640px) {
    #card-contact-buttons {
        background:#FFF;
        display:flex;
        position:fixed;
        bottom:0;
        left:0;
        width:100%;
        padding-right:75px;
    }
}

#asesoramiento-telefonico-card strong {
    font-size: 0.9rem;

    &.h1 {
        font-size:1.6rem;
        background:url('../img/phone.png') no-repeat;
        min-height:60px;
        padding-left:30px;
    }
}

.mainImg iframe {
    width:100%;
    aspect-ratio:16/9;
}
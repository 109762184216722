//init proactive search fieldsets
#proactiveSearch fieldset:nth-child(n+3) > div,
#proactiveSearch .briefing,
#proactiveSearch #labelCondicionesLegales span
 {
    display:none;
}

#proactiveSearchLanding {
    .p-5 {
        padding:10px;
    }
}

#proactiveSearchInfo {

    min-width:300px;
    
    ul {
        display:none;
    }

    h1 {
        font-size: 1.6em;
    }

    h2 {
        font-weight:normal;
    }

}

#proactiveSearch .phone {
    background:url('../img/phone.png') no-repeat;
    padding-left:30px;
    height:25px;
    display:inline-block;
}

@media screen and (min-width: 641px) {
    #proactiveSearchInfo {

        h2 a {
            color: #FFF;
        }

        h1 {
            font-size:2.5em;
            padding:1em;
            color:#FFF;
            background-image: url('../img/proactiveSearch.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            font-size:2em;
            padding:0.5em;
    
            span {
                font-size:1.8rem;
                margin-top:200px;
                display:block;
                font-size:1.5rem;
            }
        }
    
        h2 {
            background: rgba(16,42,67,1);
            color: #FFF;
            padding: 10px;
            font-size: 1.3rem;
            margin-top: -3rem;
        }
    
        ul {
    
            display:block;
            padding:20px 0 20px 0;
    
            li {
                list-style:none;
                padding:10px;
                font-size: 1.2em;
        
                img {
                    padding-right:20px;
                }
            }
        }
    }

    
}
html {
    font: 14px sans-serif;
}

body {
    color: $primary-color;
    padding:0;
    background:#FFF;
}

h1, .h1 {
    font-size:18px;
    font-weight:bold;
    color: #102A43;
}

h2, .h2 {
    font-size:16px;
    font-weight:bold;
    color: #102A43;
}

h3, .h3, h4, .h4, .h5, .h6 {
    font-size:14px;
    font-weight:bold;
    color: #102A43;
}

a {
    color: #6F8B27;
    text-decoration:none;

    &:hover {
        color: #6F8B27;
        text-decoration:underline;
    }
}

img.img-fluid {
    width:100%;
}

body > div.container-xl {
    margin:0 auto;
    min-width:320px;
    max-width:1280px;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
}

.container-fluid {
    padding:0;
    margin:0;
}

.d-none {
    display:none;
}

.d-block {
    display:block;
    width:100%;
}

.justify-content-center {
    align-items: center;
    justify-content: center;
}

.shadow {
    box-shadow: 4px 2px 13px rgba(0,0,0,0.05);
}

.border {
    border:1px solid #D9E2EC;
    padding:10px;
}

.border-top {
    border-top:1px solid #D9E2EC;
    padding-top:10px;
}

.border-bottom {
    border-bottom:1px solid #D9E2EC;
    padding-bottom:10px;
}

.rounded {
    border-radius:10px;
}

.values {
    .h1 {
        letter-spacing: -1px;
    }

    .text-secondary {
        font-size:0.8em;
    }

}

.text-secondary {
    color: #627D98;
}

.text-warning {
    color: #ffc107;
}

.bg-primary {
    background-color: #FFF;
}

.bg-secondary {
    background-color: #F0F4F8;
}

.text-success {
    color:#94B247;
}

.text-danger {
    color:#F17373
}

/*paddings*/

.p-3 {
    padding: 10px;
  }

.p-5 {
    padding: 30px;
  }

.pt-1 {
    padding-top: 3px;
}

.pt-3 {
    padding-top: 10px;
}

.pb-1 {
    padding-bottom: 3px;
}

.pb-3 {
    padding-bottom: 10px;
}

.pl-3 {
    padding-left: 10px;
}

.pl-5 {
    padding-left: 30px;
}

.p-0 {
    padding: 0;
  }

.pb-0 {
padding-bottom: 0;
}

/*margins*/

.m-0 {
    margin:0;
}

.m-1 {
    margin:3px;
}

.m-3 {
    margin:10px;
}

.m-5 {
    margin:30px;
}

.ml-1 {
    margin-left:3px;
}

.mr-1 {
    margin-right:3px;
}
.mt-1 {
    margin-top: 10px;
}
.mb-1 {
    margin-bottom: 10px;
}

.mr-3 {
    margin-right:10px;
}

.mr-5 {
    margin-right:30px;
}

.mt-3 {
    margin-top: 10px;
}
.mt-4 {
    margin-top: 20px;
}

.mt-5 {
    margin-top: 30px;
}

.mb-3 {
    margin-bottom: 10px;
}

.mb-5 {
    margin-bottom: 30px;
}

.mx-auto {
    margin-left:auto;
    margin-right:auto;
}

.me-2 {
    margin-right: .5rem!important;
}

.me-auto {
    margin-right: auto!important
}

.mt-0 {
    margin-top:0;
}

.mb-0 {
    margin-bottom:0;
}

/* width */
.w-100 {
    width:100%;
}

.btn-primary {
    color: #FFF;
}

.btn-primary, .btn-outline-primary {
    padding: 1em;
    &:hover {
        text-decoration:none;
    }
}

.btn-info {
    padding:1em;
    background-color: #007bff;
    color:#FFF;
}

.text-center {
    text-align:center;
}

.text-right {
    text-align:right;
}

.listnone {
    li {
        list-style:none;
        padding-left:20px;

        i {
            position:absolute;
            margin-left:-20px;
            font-weight:bold;
            font-size:18px;
        }
    }
}

.content {

    ol, ul {
        margin-left:2rem;
    }

    dl.dl-inline {
        display:grid;
        grid-template-columns: 30% 70%;
    }
} 

@media screen and (max-width:420px) {
    .d-sm-flex {

        display:flex;

        &.flex-sm-column {
            flex-direction:column;
        }
    }
}

@media screen and (min-width:768px) {
    .content-grid {
        display:grid;
        grid-template-columns:50% 50%; 
        grid-gap: 1rem;

        div {
            h2 a {
                color:#6F8B27;
            }

            img {
                width:100%;
            }
        }
    }    
}
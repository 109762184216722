#hero {
    background-image: url('../img/inforesidencias-hero.jpg');

    h1 {
        span {
            color: #6F8B27;
        }
    }

    p.buttons {
        display:flex;
        gap: 10px;
        flex-direction: column;
    }

    .btn-primary {

        font-size: 12px;
        display:block;
        align-items:center;
        border-radius: 8px;
        line-height:1.2;
        padding-left:70px;

        span {
            font-size:20px;
            display:block;
        }

        &.btn-home-proactive-search {
            background: #94B247 url('../img/btn-home-proactive-search.svg') no-repeat 20px center;
            background-size:30px;
            border: 0;
            width:100%;
        }

        &.btn-home-lists {
            background: #94B247 url('../img/btn-home-lists.svg') no-repeat 20px center;
            background-size:30px;
        }
    }

    label, input, select {
        font-size:18px;
    }

    #formulario-busqueda-proactiva {
        margin-bottom:2rem;
    }

}

#hero-buttons-mobile .btn-primary.btn-home-proactive-search {
    display:none;
}

.reinforcements {

    border-top: 1px solid #D9E2EC;

    p {
        font-size: 14px;
        color: #486581;
        padding: 20px;
        padding-left: 75px;
        line-height:1.2;

        span {
            font-size:24px;
            color: #102A43;
            display:block;
        }

        img {
            position:absolute;
            margin-left:-75px;
        }
    }
}

.job-home-section {
    background: #94B247 url("../img/bg-job-home.png");
    color: #FFF;
    padding:10px 40px;
    align-items:center;
    justify-content: center;
    flex-direction: column;

    a.btn {
        display:block;
        background-color: #FFF;
        color: #627D98;
        box-shadow: 0 4px 4px rgba(0,0,0,0.25);

        span {
            color: #102A43;
            display:block;
        }
    }
}

#lista-infoclasificados-superior {
    order:1;
}

#hero-buttons-desktop {
    display:none;
}



@media screen and (min-width : 640px)  {
    #hero {
        height: 514px;
        padding:0 40px 40px 40px;

        h1 {
            font-size: 36px;
            margin-top:90px;
            width:60%;
        }

        p {
            font-size:18px;
            width:60%;
        }

        .btn-primary {
            display:flex;
            font-size: 14px;
            border-radius: 8px;
            width:320px;
            line-height:1.2;
            height:95px;
            background-size: 30px;
            height:55px;
            margin-top:22px;

            span {
                font-size:24px;
            }
        }

        #formulario-busqueda-proactiva {
            width:40%;
        }

    }

    .job-home-section {
        font-size: 36px;
        flex-direction: row;

        a.btn {
            font-size: 20px;
        }
    }

    #centros-mas-actualizados > div > div {
        min-height:420px;
    }

    #hero-buttons-mobile {
        display:none;
    }
}

@media screen and (max-width:475px) {
    #hero .btn-primary span {
        text-align:left;
    }
}
#table-compare {
    overflow-x: scroll;
}

table {

    thead {
        td {
            background-color: #f0f4f8;
        }
    }

    tr {
        td, th {
            padding:1em;
            border:1px solid #D9E2EC;
        }

        &.header th {
            background-color: #f0f4f8;
        }

    }

}
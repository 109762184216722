.pagination {
    
    gap: 2px;

    a {
        color: #102A43;
    }
    .page-item .page-link {
        border-color: #D9E2EC;
        background-color: #D9E2EC;
    }

    .page-item.active .page-link {
        border-color: #102A43;
        background-color: #102A43;
    }
}
footer {
    color: #FFFFFF;
    background-color:#627D98;
    background-image: url('../img/bg-footer.png');
    background-size: cover;
    padding: 0;
    margin: 0;
}

footer > div {
    margin:0 auto;
    min-width:320px;
    max-width:1280px;
    padding: 3rem 1.5rem
}

footer div.certificates {
    padding: 1rem 1.5rem;
    background-color: #102A43;
    max-width:100%;
}

footer h4 {
    color: #FFFFFF;
}

footer a {
    color: #FFFFFF;
    text-decoration:none;
}

footer a:hover {
    color: #FFFFFF;
    text-decoration:underline;
}